import React from "react";

function NextBtn() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="52"
        height="52"
        rx="26"
        // fill="#506A47"
      />
      <path
        d="M18 26H34M34 26L32.2876 25.5524C29.7619 24.8922 28 22.6106 28 20M34 26L32.2876 26.4476C29.7619 27.1078 28 29.3894 28 32"
        stroke="#FFFAE3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NextBtn;
