"use client";

import React, { useEffect, useRef, useState } from "react";
import styles from "./navbar.module.scss";
import Logo from "../../icons/Logo";
import PhoneIcon from "../../icons/PhoneIcon";
import BurgerIcon from "../../icons/BurgerIcon";
import CallIcon from "../../icons/CallIcon";
import CloseIcon from "../../icons/CloseIcon";
import InstagramIcon from "../../icons/burger-menu/InstagramIcon";
import FacebookIcon from "../../icons/burger-menu/FacebookIcon";
import { Link } from "react-router-dom";

type Props = {
  scrolling: boolean;
};
function Navbar({ scrolling }: Props) {
  const [open, setOpen] = useState(false);
  const topMenuRef = useRef<HTMLDivElement>(null);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = (event: MouseEvent) => {
    if (
      topMenuRef.current &&
      !topMenuRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClose);
    } else {
      document.removeEventListener("mousedown", handleClose);
    }

    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [open]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={styles.navbarContainer}
      style={
        scrolling
          ? {
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(20px)",
              background: "rgba(251,245,229, 0.7)",
            }
          : {}
      }
    >
      {open && (
        <div className={styles.topMenu} ref={topMenuRef}>
          <div className={styles.menuWrapper}>
            <div className={styles.menuBtns}>
              <Link to="/despre" className={styles.menuBtn}>
                Despre
              </Link>
              <Link to="/contacte" className={styles.menuBtn}>
                Contacte
              </Link>
              <div className={styles.socialBox}>
                <Link
                  to="https://www.instagram.com/vatici_resort/?hl=en"
                  target="_blank"
                  className={styles.socialBtn}
                >
                  <InstagramIcon />
                </Link>
                <Link
                  to="https://www.facebook.com/vatici/"
                  target="_blank"
                  className={styles.socialBtn}
                >
                  <FacebookIcon />
                </Link>
              </div>
            </div>
            <a href="tel:+37368686123" className={styles.phoneTextBig}>
              +373 686 86 123
            </a>
          </div>
        </div>
      )}
      <div className={styles.navbarBox}>
        <div className={styles.navbarElement}>
          <div className={styles.linkBox}>
            <Link className={styles.link} to="/contacte">
              Contacte
            </Link>
            <Link className={styles.link} to="/despre">
              Despre
            </Link>
          </div>
        </div>
        <div className={styles.logoWrapper}>
          <Link to="/" onClick={scrollToTop} className={styles.logoBox}>
            <Logo />
          </Link>
        </div>
        <div
          className={styles.navbarElement}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 12,
          }}
        >
          <a href="tel:+37368686123" className={styles.phoneText}>
            +373 686 86 123
          </a>
          <a href="tel:+37368686123" className={styles.callBtn}>
            <div className={styles.callNow}>SUNĂ ACUM</div>
            <PhoneIcon />
          </a>
        </div>
        <div className={styles.btns}>
          <a href="tel:+37368686123" className={styles.callIcon}>
            <CallIcon />
          </a>
          <div
            className={styles.burgerIcon}
            style={{
              transition: "transform 0.3s ease",
              transform: open ? "rotate(90deg)" : "rotate(0deg)",
            }}
            onClick={() => handleOpen()}
          >
            {!open ? <BurgerIcon /> : <CloseIcon />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
